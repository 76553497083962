<app-nav></app-nav>
<div class="container">
  <div class="card">
    <div class="card-header">
      <ul class="nav nav-pills card-header-pills">
        <li class="nav-item mr-auto">
          <h3>{{clientData.name}}</h3>
        </li>

        <li class="nav-item">
          <button class="btn wm-button btn-block right-align" (click)="saveButtonPressed()" type="submit">
            Save
          </button>
        </li>
      </ul>
    </div>
    <div class="card-body">
      <table class="table table-striped">
        <tbody>
        <tr>
          <td>
            Company ID
          </td>
          <td>
            {{ clientData.id }}
          </td>
          <td>
            <strong>{{nameForPlanId(clientData.planId)}}</strong> pakket wijzigen naar:
          </td>
          <td class="pr-md-5">
            <select [value]="selectedPlanId" (change)="packageChange($event)" class="form-control">
              <option value="1">Spruit</option>
              <option value="2">Moestuin</option>
              <option value="3">Kas</option>
              <option value="5">Forever Free (Legacy)</option>
              <option value="6">Essentials</option>
              <option value="7">Team</option>
              <option value="8">Professional</option>
              <option value="9">Enterprise (Legacy)</option>
              <option value="10">Forever Free</option>
              <option value="11">Standard</option>
              <option value="12">Business</option>
              <option value="13">Enterprise</option>
              <option value="14">Starter</option>
            </select>
          </td>
        </tr>

        <tr>
          <td>
            Created At
          </td>
          <td>
            {{ clientData.createdAt | date:'longDate'}}
          </td>
          <td>
            Active Subscription:
          </td>
          <td class="pr-md-5">
            <select [value]="selectActiveSubscription" (change)="subscriptionChange($event)" class="form-control">
              <option value="0">No</option>
              <option value="1">Yes</option>
            </select>
          </td>
        </tr>

        <tr>
          <td>
            Actieve gebruikers
          </td>
          <td>
            {{ clientData.active_users }}
          </td>
          <td>
            Facebook
          </td>
          <td>
            <button class="btn wm-button btn-block right-align" (click)="deleteFacebookAccount()">Delete</button>
          </td>
        </tr>

        <tr>
          <td>
            Gedeactiveerde gebruikers
          </td>
          <td>
            {{ clientData.deactivated_users }}
          </td>
          <td>
            Contacten
          </td>
          <td>
            {{getTotalsText(clientData.total_contacts, clientData.max_contacts)}}
          </td>
        </tr>

        <tr>
          <td>
            Gesprekken deze maand
          </td>
          <td>
            {{getTotalsText(clientData.total_conversations_this_month, clientData.max_conversations)}}
          </td>
          <td>
            Kanalen
          </td>
          <td>
            {{getTotalsText(clientData.total_channels, clientData.max_channels)}}
          </td>
        </tr>

        <tr>
          <td>
            Gesprekken vorige maand
          </td>
          <td>
            {{getTotalsText(clientData.total_conversations_previous_month, clientData.max_conversations)}}
          </td>
          <td>
            Stripe ID
          </td>
          <td>
            <input type="text"
                   class="stripe-customer-input"
                   [(ngModel)]="stripeId"
                   (ngModelChange)="onStripeIdInputChane($event)"
            >
          </td>
        </tr>
        <tr>
          <td>
            Heeft Watermelon toegang?
          </td>
          <td>
            <ng-container *ngIf="hasWatermelonAccess(); else noAccess">
              Ja
            </ng-container>
            <ng-template #noAccess>
              Nee
            </ng-template>
          </td>
          <td>

          </td>
          <td>

          </td>
        </tr>
        <tr *ngIf="chatbot">
          <td>
            Chatbot ID
          </td>
          <td>
            {{ chatbot.id }}
          </td>
          <td>
            {{ chatbot.name }}
          </td>
          <td>
            <button class="btn wm-button btn-block right-align"
                    (click)="resetChatbotTraining(chatbot.id)">Reset chatbot training</button>
          </td>
        </tr>
        <tr *ngIf="trialPeriod">
          <td>
            Aantal keer verlengd: {{trialPeriod.counter}}
          </td>
          <td>
            Eind datum: {{trialPeriod.end | date : 'dd-MM-yy'}}
          </td>
          <td>
            <button class="btn wm-button btn-block right-align"
                    (click)="extendTrial()">Verlengen</button>
          </td>
          <td>
              <button class="btn wm-button btn-block right-align"
              (click)="deleteTrial(trialPeriod.id)">Beëindiging</button>
          </td>
        </tr>
        <tr>
          <td>
            Toegang Watermelon Pulse Beta
          </td>
          <td>
            <div class="switch-box">
              <input type="checkbox"
                     id="toggle"
                     class="checkbox"
                     (change)="switchChange()"
                     [checked]="pulseBetaSwitch">
              <label for="toggle" class="switch-toggle"></label>
            </div>
          </td>
          <td>
          </td>
          <td>
          </td>
        </tr>
        <tr>
          <td>
            Pulse chatbots limit
          </td>
          <td>
            <div class="pulse-chatbot-limit">
              <input type="number"
                     id="limit"
                     placeholder="3"
                    [(ngModel)]="chatbotLimit"
                    (ngModelChange)="chatbotLimitChange($event)"
                    [class.error]="limitError">
            </div>
          </td>
          <td>
          </td>
          <td>
          </td>
        </tr>
        <tr>
          <td>
            Web Crawler limit reset
          </td>
          <td>
            <ng-container *ngIf="pulseChatbots && pulseChatbots.length === 0">
              <td class="empty-pulse-chatbots">
                No Pulse Chatbots
              </td>
            </ng-container>
            <ng-container *ngIf="pulseChatbots && pulseChatbots.length > 0">
              <select [value]="selectedPulseChatbotId" (change)="onPulseChatbotSelect($event)" class="form-control">
                <option *ngFor="let pulseChatbot of pulseChatbots" [value]="pulseChatbot.id">
                  {{ pulseChatbot.name }}
                </option>
              </select>
            </ng-container>
          </td>
          <td>
            <div class="pulse-chatbot-limit">
              <button class="btn wm-button btn-block right-align"
                      [class.wm-button-disabled]="pulseChatbots && pulseChatbots.length === 0"
                      (click)="pulseChatbots && pulseChatbots.length > 0 && onResetCrawlLimit()"
              >
                Reset web crawler limit
              </button>
            </div>
          </td>
          <td>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="card" *ngIf="hasWatermelonAccess()">
    <div class="card-header">
      <ul class="nav nav-pills card-header-pills">
        <li class="nav-item mr-auto">
          <h3>Login als Watermelon support agent</h3>
        </li>
        <li>
          <button class="btn wm-button btn-block right-align" (click)="checkIfSuperAdminLogin()">
            Inloggen
          </button>
        </li>
      </ul>
    </div>
  </div>
  <div class="card" *ngIf="clientData.users && clientData.users.length > 0">
    <div class="card-header">
      <ul class="nav nav-pills card-header-pills">
        <li class="nav-item mr-auto">
          <h3>Gebruikers</h3>
        </li>

      </ul>
    </div>
    <div class="card-body">
      <table class="table table-striped">
        <thead>
        <tr>
          <th>Voornaam</th>
          <th>Achternaam</th>
          <th>E-mail</th>
          <th>Type</th>
          <th>Totaal logins</th>
          <th>Laatste login</th>
        </tr>
        </thead>
        <tr *ngFor="let user of clientData.users;">
          <td>{{user.firstName}}</td>
          <td>{{user.lastName}}</td>
          <td>{{user.email}}</td>
          <td>{{user.userType}}</td>
          <td>{{user.timesLoggedIn}}</td>
          <td>{{user.lastLogin | date : 'dd-MM-yy HH:mm' }}</td>
        </tr>
      </table>
    </div>
  </div>
</div>
