import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import Chatbot from "../interfaces/chatbot";
import { environment as env } from 'src/environments/environment';

const API: string = env.urls.api;

@Injectable({
    providedIn: 'root'
})
export class ChatbotService {
    constructor(private http: HttpClient) {}

    private getHeaders(): HttpHeaders {
        const authDetails = JSON.parse(localStorage.getItem('auth'));
        const accessToken = authDetails.accessToken; // your token

        return new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        });
    }

    public GetChatboyByCompanyID(company_id: number): Observable<Chatbot> {
        return this.http.get<Chatbot>(`${API}/chatbot/${company_id}`, {
            headers: this.getHeaders()
        })
    }

    public ResetChatbotTraining(chatbot_id: number): void {
        this.http.patch(`${API}/chatbot/reset_training/${chatbot_id}`, {}, {
            headers: this.getHeaders()
        }).subscribe();
    }
}
