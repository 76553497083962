import {Component, OnInit} from '@angular/core';
import {AppFacade} from 'src/app/facades/app.facade';
import {UtilsService} from '../../service/utils.service';
import {ToastrService} from 'ngx-toastr';
import {environment as env} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import Swal from 'sweetalert2';
import {UserService} from '../../service/user.service';
import {SettingsService} from '../../service/settings.service';
import { ChatbotService } from 'src/app/service/chatbot.service';
import Chatbot from 'src/app/interfaces/chatbot';
import Trial from 'src/app/interfaces/trial';
import { TrialService } from 'src/app/service/trial.service';
import {AuthenticationService} from '../../service/authentication.service';

const API: string = env.urls.api;
const PLATFORM: string = env.urls.platform;
@Component({
  selector: 'app-clients-info',
  templateUrl: './clients-info.component.html',
  styleUrls: ['./clients-info.component.scss']
})
export class ClientsInfoComponent implements OnInit {

  public clientData: any;
  public selectedPlanId: number;
  public selectActiveSubscription: number;
  public createdAtDate: string;
  public companyID: string;
  public chatbot: Chatbot;
  public trialPeriod: Trial;
  public pulseBetaSwitch: boolean;
  public chatbotLimit: number;
  public stripeId: string;
  public limitError: boolean;
  public pulseChatbots: any;
  public selectedPulseChatbotId: number;
  constructor(
    private appFacade: AppFacade,
    private utils: UtilsService,
    private toastr: ToastrService,
    private http: HttpClient,
    private userService: UserService,
    private settingsService: SettingsService,
    private chatbotService: ChatbotService,
    private trialService: TrialService,
    private authService: AuthenticationService
  ) {
    this.limitError = false;
    this.pulseChatbots = [];
  }

  packageChange($event) {
    this.selectedPlanId = $event.target.value;
  }

  subscriptionChange($event) {
    this.selectActiveSubscription = $event.target.value;
  }

  saveButtonPressed() {
    const companyDetails = {
      planId: Number(this.selectedPlanId),
      manualPlan: !!Number(this.selectActiveSubscription),
      stripeCustomerId: this.stripeId
    };

    this.appFacade.changeCompanyDetails(this.clientData.id, companyDetails).subscribe((result) => {
      if (result.status === 200) {
        this.toastr.success(`Uw wijzigingen zijn opgeslagen`, 'Opgeslagen');
      } else {
        this.toastr.error(`Er is een fout opgetreden...`, 'Error');
      }
    });
    if (!this.limitError) {
      this.updateChatbotLimit();
    }
  }

  nameForPlanId(id: number): string {
    return this.utils.nameForPlanId(id);
  }

  deleteFacebookAccount() {
    const authDetails = JSON.parse(localStorage.getItem('auth'));
    const accessToken = authDetails.accessToken; // your token

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + accessToken
    });

    this.http.delete(`${API}/company/${this.companyID}`, {headers: headers}).subscribe();
  }

  async warnUser(): Promise<boolean> {
    const confirm = await Swal.fire({
      title: 'Let op!',
      text: 'Er is al een Watermelon support agent ingelogd. Deze support agent wordt automatisch uitgelogd zodra jij inlogd',
      icon: 'error',
      confirmButtonColor: '#05BA6E',
      showCancelButton: true,
      confirmButtonText: 'Overnemen',
      cancelButtonText: 'Annuleren'
    });

    if (confirm.isConfirmed) {
      return Promise.resolve(true);
    }
    return Promise.reject(false);
  }

  redirectToPlatform(token: string) {
    const url = `${PLATFORM}/token-login?token=${token}`;
    window.open(url, '_blank');
  }

  randomString(): string {
    const date = new Date();
    return `${date.getDay()}${date.getMonth()}${date.getFullYear()}${date.getHours()}${date.getMinutes()}${this.companyID}`;
  }

  loginAsSupportAgent() {
    const authDetails = JSON.parse(localStorage.getItem('auth'));
    const firstname = authDetails.firstName;

    const email = `support-${firstname}-${this.randomString()}@watermelon.co`;
    this.userService.adminLogin(this.companyID, email)
      .subscribe(async (response) => {
        this.redirectToPlatform(response['registrationToken']);
      });
  }

  checkIfSuperAdminLogin() {
    this.settingsService.isSuperAdminLoggedIn(this.companyID)
      .subscribe(async (response) => {
        if (response['is_logged_in']) {
          try {
            const acceptForceLogin = await this.warnUser();
            if (acceptForceLogin) {
              return this.loginAsSupportAgent();
            } else {
              return false;
            }
          } catch (e) {
            return false;
          }
        }
        return this.loginAsSupportAgent();
      });
  }

  getTotalsText(first, second): string {
    let text = first ? first : 0;
    text = text + '/';
    text = text + (second ? second : 'Onbeperkt');
    return text;
  }

  hasWatermelonAccess(): boolean {
    if (this.clientData.admin_access) {
      return true;
    }
    return false;
  }

  resetChatbotTraining(chatbot_id: number): void {
    this.chatbotService.ResetChatbotTraining(chatbot_id);
    this.toastr.success(`Training is gerest`, 'Reset training');
  }

  extendTrial(): void {
    this.trialService.ExtendTrialByCompanyID(parseInt(this.companyID, 0));
    this.toastr.success(`Is verlengd met 7 dagen`, 'Trial');
  }

  cancelTrial(id: number): void {
    this.trialService.CancelTrialByID(id);
    this.toastr.success(`Is geannuleerd (vergeet niet om te zetten naar standaard of business pakkeet indien nodig)`, 'Trial');
  }

  deleteTrial(id: number): void {
    this.trialService.DeleteTrialByID(id);
    this.toastr.success(`Is geannuleerd (vergeet niet om te zetten naar standaard of business pakkeet indien nodig)`, 'Trial');
    this.trialPeriod = null;
  }

  public switchChange(): void {
    this.pulseBetaSwitch = !this.pulseBetaSwitch;
    this.authService.updatePulseBeta(this.clientData.id).subscribe(res => {
    });
  }

  public chatbotLimitChange(change): void {
    this.chatbotLimit = change;
    this.limitError = change < 0;
  }

  public onResetCrawlLimit() {
    this.authService.resetWebCrawlerLimit(this.selectedPulseChatbotId).subscribe((result) => {
      this.toastr.success(`Your web crawler limit was reset successfully!`, 'Success');
    }, error => {
      this.toastr.error(`There was an error reseting web crawler limit. Please try again...`, 'Error');
    });
  }

  public onPulseChatbotSelect(event): void {
    this.selectedPulseChatbotId = event.target.value;
  }

  public onStripeIdInputChane(value): void {
    this.stripeId = value;
  }

  private updateChatbotLimit(): void {
    this.authService.updatePulseChatbotLimit(this.clientData.id, this.chatbotLimit).subscribe();
  }

  private getChatbot(): void {
    this.chatbotService.GetChatboyByCompanyID(parseInt(this.companyID, 0)).subscribe((chatbot) => {
      this.chatbot = chatbot;
    });
  }

  private getTrial(): void {
    this.trialService.GetTrialByCompanyID(parseInt(this.companyID, 0)).subscribe((trial) => {
      this.trialPeriod = trial;
    });
  }

  private getPulseBeta(): void {
    this.authService.getPulseBetaUserData(this.clientData.id).subscribe(res => {
      this.pulseBetaSwitch = res.has_beta;
    });
  }

  private getPulseChatbotLimit(): void {
    this.authService.getPulseChatbotLimit(this.clientData.id).subscribe(res => {
      console.log(res)
      this.chatbotLimit = res.amount;
    }, (error => {
      this.chatbotLimit = 3;
    }));
  }

  private getPulseChatbots(): void {
    this.authService.getPulseChatbots(this.clientData.id).subscribe(res => {
      this.pulseChatbots = res;
      if (this.pulseChatbots.length > 0) {
        this.selectedPulseChatbotId = this.pulseChatbots[0].id;
      }
    }, error => {
      this.toastr.error(`There was an error retrieving pulse chatbots. Please reload!`, 'Error');
    });
  }

  ngOnInit() {
    this.clientData = JSON.parse(localStorage.getItem('clientData'));
    this.stripeId = this.clientData.stripe_customer_id;
    this.selectedPlanId = this.clientData.planId;
    this.selectActiveSubscription = this.clientData.manualPlan ? 1 : 0;
    this.createdAtDate = this.clientData.createdAt;
    this.companyID = this.clientData.id;
    this.getChatbot();
    this.getTrial();
    this.getPulseBeta();
    this.getPulseChatbotLimit();
    this.getPulseChatbots();
  }

}
